import React from "react";
import TopNav from "./topnav";
import MainNav from "./mainnav";
const Header = () => {
  return (
    <>
      <TopNav />
      <MainNav />
    </>
  );
};
export default Header;
