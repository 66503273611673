import React from "react";
import logos from "../assets/img/logo.png";
const Errors = () => {
  return (
    <>
      <div className="errors">
        <div>
          <img src={logos} />
          <h3>Errors Something Bad</h3>
        </div>
      </div>
    </>
  );
};
export default Errors;
